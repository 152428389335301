import Vue from 'vue'
import VueCookie from 'vue-cookie'
import App from './App.vue'
import router from './router'
import store from './store'
import './normalize.css'
import httpRequest from './utils/httpRequest'

Vue.config.productionTip = false
Vue.prototype.$http = httpRequest
Vue.use(VueCookie)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
