import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: [
    {
      path: '/',
      name: 'p/fd202205',
      component: () => import('../pages/promotion/free_design.vue'),
    },
    {
      path: '/p/FD',
      name: 'p/free_design',
      component: () => import('../pages/promotion/free_design.vue'),
    },
    {
      path: '/p/FDQ',
      name: 'p/free_design_qrcode',
      component: () => import('../pages/promotion/free_design_qrcode.vue'),
    },
    {
      path: '/p/DQ',
      name: 'p/design_code',
      component: () => import('../pages/promotion/design_qrcode.vue'),
    },
    {
      path: '/userAgreement',
      name: 'userAgreement',
      component: () => import('../pages/promotion/UserAgreement.vue'),
    },
    {
      path: '/p/mp',
      name: 'blank_promotion',
      component: () => import('../pages/promotion/maopi_618.vue'),
    },
    {
      path: '/p/jj',
      name: 'hardcover_promotion',
      component: () => import('../pages/promotion/jiaju_618.vue'),
    },
    {
      path: '/p/p6/*',
      name: 'test',
      component: () => import('../pages/promotion/house_custom.vue'),
    },
    {
      path: '/p/ir',
      name: 'investmentRegister',
      component: () => import('../pages/form/newInvestmentRegister.vue'),
    },
    {
      path: '/p/ir/*',
      name: 'investmentRegister',
      component: () => import('../pages/form/newInvestmentRegister.vue'),
    },
    {
      path: '/p/kg',
      name: 'kitchenGoods',
      component: () => import('../pages/promotion/kitchen_goods.vue'),
    },
    {
      path: '/p/kg/*',
      name: 'kitchenGoods',
      component: () => import('../pages/promotion/kitchen_goods.vue'),
    },
  ],
})
