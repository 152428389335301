import Vue from 'vue'
import axios from 'axios'
import merge from 'lodash/merge'
import qs from 'qs'
import { Toast } from 'vant'
import router from '../router'

// 超时时间
axios.defaults.timeout = 30000
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
axios.defaults.headers = { 'Content-Type': 'application/json; charset=utf-8' }
// 非生产环境 && 开启代理, 接口前缀统一使用[/platform-plus]前缀做代理拦截!
// const BASE_URL =
//   process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/platform-plus' : window.SITE_CONFIG.baseUrl
const BASE_URL = 'https://h5.jpcmall.com/platform-api'
// const BASE_URL = 'http://localhost:8809/platform-api'
// 对面暴露的基础请求路径
axios.BASE_URL = BASE_URL

/**
 * 请求拦截
 */
// eslint-disable-next-line no-unused-vars
let loading
let showError = true
axios.interceptors.request.use(
  (config) => {
    let showLoading = true
    if (config.loading === false) {
      showLoading = false
    }
    if (config.showError === false) {
      showError = false
    }
    if (showLoading) {
      loading = Toast.loading({ duration: 0, forbidClick: true, message: '加载中...' })
    }
    // 请求头带上token
    // eslint-disable-next-line no-param-reassign
    config.headers.token = Vue.cookie.get('token') || ''
    // 请求地址处理
    // eslint-disable-next-line no-param-reassign
    config.url = BASE_URL + config.url
    const type = config.method
    const defaults = {}
    if (type === 'post') {
      // post请求参数处理
      // json: 'application/json; charset=utf-8'
      // form: 'application/x-www-form-urlencoded; charset=utf-8'
      if (config.headers['Content-Type'] === 'multipart/form-data') {
        console.log('')
      } else if (
        config.headers['Content-Type'] === 'application/x-www-form-urlencoded' &&
        typeof config.data === 'object'
      ) {
        // eslint-disable-next-line no-param-reassign
        config.data = qs.stringify(config.data)
      } else {
        // eslint-disable-next-line no-param-reassign
        config.data = JSON.stringify(config.data)
      }
    } else if (type === 'get') {
      // get请求参数处理
      // eslint-disable-next-line no-param-reassign
      config.params = merge(defaults, config.params)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * 响应拦截
 */
axios.interceptors.response.use(
  (response) => {
    Toast.clear()
    if (response.data && response.data.code === 401) {
      // 401, token失效
      router.push({ name: 'login' })
    }
    if (response.request.responseType !== 'blob' && !(response.data && response.data.code === 0)) {
      console.info(response)
      if (showError) {
        Toast.fail({
          message: response.data.msg,
          duration: 5000,
        })
      }
      return Promise.reject(response)
    }
    // 会对下载文件的请求，返回是错误
    if (response.data instanceof Blob && response.data.type === 'application/json') {
      const reader = new FileReader()
      // eslint-disable-next-line consistent-return,func-names
      reader.onload = function () {
        const rs = JSON.parse(reader.result)
        if (rs.code !== 0) {
          if (showError) {
            Toast.fail({
              message: response.data.msg,
              duration: 5000,
            })
          }
          return Promise.reject(response)
        }
      }
      reader.readAsText(response.data, 'utf-8')
    }
    return response
  },
  (error) => {
    Toast.clear()
    Toast.fail({
      message: '网络异常，请稍后重试',
      duration: 3000,
    })
    return Promise.reject(error)
  }
)

// eslint-disable-next-line func-names
axios.download = async function (url, params) {
  const response = await this.request({
    url,
    method: 'get',
    params,
    responseType: 'blob',
  })

  const { data } = response
  // 获取headers中的filename文件名
  const tempName = response.headers['content-disposition'].split(';')[1].split('filename=')[1]
  // iconv-lite解决中文乱码
  // const iconv = require('iconv-lite')
  // iconv.skipDecodeWarning = false // 忽略警告
  // const fileName = iconv.decode(tempName, 'UTF-8')
  const fileName = decodeURI(tempName)
  const blob = new Blob([data], { type: 'application/vnd.ms-excel' }) // 转换成二进制对象
  if ('download' in document.createElement('a')) {
    // 不是IE浏览器
    // eslint-disable-next-line no-shadow
    const url = window.URL.createObjectURL(blob) // 二进制对象转换成url地址
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link) // 下载完成移除元素
    window.URL.revokeObjectURL(url) // 释放掉blob对象
  } else {
    window.navigator.msSaveBlob(blob, fileName)
  }
}
export default axios
